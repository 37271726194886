import { render, staticRenderFns } from "./FilterItems.vue?vue&type=template&id=2eff0750&scoped=true&"
import script from "./FilterItems.vue?vue&type=script&lang=js&"
export * from "./FilterItems.vue?vue&type=script&lang=js&"
import style0 from "./FilterItems.vue?vue&type=style&index=0&id=2eff0750&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2eff0750",
  null
  
)

export default component.exports
import QSlider from 'quasar/src/components/slider/QSlider.js';
import qInstall from "../../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QSlider});
