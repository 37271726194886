import { render, staticRenderFns } from "./WorkflowsOverview.vue?vue&type=template&id=03e2c0f6&scoped=true&"
import script from "./WorkflowsOverview.vue?vue&type=script&lang=js&"
export * from "./WorkflowsOverview.vue?vue&type=script&lang=js&"
import style0 from "./WorkflowsOverview.vue?vue&type=style&index=0&id=03e2c0f6&prod&lang=scss&scoped=true&"
import style1 from "./WorkflowsOverview.vue?vue&type=style&index=1&id=03e2c0f6&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03e2c0f6",
  null
  
)

export default component.exports
import QChip from 'quasar/src/components/chip/QChip.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QChatMessage from 'quasar/src/components/chat/QChatMessage.js';
import QMenu from 'quasar/src/components/menu/QMenu.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QChip,QAvatar,QChatMessage,QMenu});
