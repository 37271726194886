import { render, staticRenderFns } from "./OverviewReportCharts.vue?vue&type=template&id=7c50413d&scoped=true&"
import script from "./OverviewReportCharts.vue?vue&type=script&lang=js&"
export * from "./OverviewReportCharts.vue?vue&type=script&lang=js&"
import style0 from "./OverviewReportCharts.vue?vue&type=style&index=0&id=7c50413d&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7c50413d",
  null
  
)

export default component.exports
import QIcon from 'quasar/src/components/icon/QIcon.js';
import qInstall from "../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QIcon});
