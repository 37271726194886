<template>
  <div class="reports">
    <template>
      <BaseScrollbar height="calc(100vh - 265px)">
        <div id="search">
          <!-- pie chart -->
          <div class="row items-start q-col-gutter-sm q-my-sm">
            <template v-for="(item, index) in workflowCount">
              <div :key="index" class="col">
                <div class="chart chartBox">
                  <div class="row text-capitalize items-center header">
                    {{ item.workflowStatus }}
                  </div>
                  <div class="column">
                    <template v-if="item.matchingKey === 'totalRequests'">
                      <div
                        class="text-primary col self-center q-mt-lg q-mb-sm count"
                      >
                        {{ item.totalCount }}
                      </div>
                    </template>
                    <template v-if="item.matchingKey === 'completedCount'">
                      <div
                        class="text-primary col self-center q-mt-lg q-mb-sm count"
                      >
                        {{ item.totalCount }}
                      </div>
                    </template>
                    <template v-if="item.matchingKey === 'inboxCount'">
                      <div
                        class="text-orange col self-center q-mt-lg q-mb-sm count"
                      >
                        {{ item.totalCount }}
                      </div>
                    </template>
                    <template v-if="item.matchingKey === 'processCount'">
                      <div
                        class="text-green col self-center q-mt-lg q-mb-sm count"
                      >
                        {{ item.totalCount }}
                      </div>
                    </template>
                    <template v-if="item.matchingKey === 'slaOverdue'">
                      <div
                        class="text-red col self-center q-mt-lg q-mb-sm count"
                      >
                        {{ item.totalCount }}
                      </div>
                    </template>
                    <template v-if="item.matchingKey === 'slaProcessing'">
                      <div
                        class="text-orange col self-center q-mt-lg q-mb-sm count"
                      >
                        {{ item.totalCount }}
                      </div>
                    </template>
                    <template v-if="item.matchingKey === 'slaAchieved'">
                      <div
                        class="text-green col self-center q-mt-lg q-mb-sm count"
                      >
                        {{ item.totalCount }}
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </template>
          </div>
          <!-- pie chart -->
        </div>

        <div class="row q-mt-sm">
          <div class="col-8 q-pr-sm q-mb-sm">
            <div class="chart">
              <div class="row item-center q-body q-mb-sm">
                <div
                  class="col-auto q-mr-xs cursor-pointer header"
                  :class="chartDataDrilldownStage.month ? 'text-secondary' : ''"
                  @click="
                    chartDataDrilldownStage.month = '';
                    chartDataDrilldownStage.week = '';
                    getWorkflowChartCount(workflowId);
                  "
                >
                  Month
                </div>
                <div
                  v-if="chartDataDrilldownStage.month"
                  class="col-auto cursor-pointer header"
                  :class="chartDataDrilldownStage.week ? 'text-secondary' : ''"
                  @click="
                    chartDataDrilldownStage.week = '';
                    getWorkflowChartCount(
                      workflowId,
                      chartDataDrilldownStage.month
                    );
                  "
                >
                  / {{ chartDataDrilldownStage.month }}
                </div>
                <div
                  v-if="chartDataDrilldownStage.week"
                  class="col-auto q-ml-xs header"
                  @click="chartDataDrilldownStage.week = ''"
                >
                  / {{ chartDataDrilldownStage.week }}
                </div>
              </div>
              <vue-highcharts
                ref="refWorkflowChart"
                :highcharts="Highcharts"
                :options="chartData"
              ></vue-highcharts>
            </div>
          </div>

          <template v-if="departmentTable.length">
            <div id="department-type" class="col-4 chart">
              <BaseScrollbar height="330px">
                <table id="departmentTable">
                  <thead>
                    <tr>
                      <!-- columns -->

                      <th v-for="column in departmentColumns" :key="column.id">
                        {{ column.label }}
                      </th>

                      <!-- ... -->
                    </tr>
                  </thead>

                  <tbody>
                    <template v-for="item in departmentTable">
                      <tr :key="item.id">
                        <!-- columns -->

                        <td
                          v-for="column in departmentColumns"
                          :key="column.id"
                        >
                          <slot
                            :name="column.name"
                            :value="item[column.name]"
                            :item="item"
                          >
                            <template v-if="column.name === 'Department'">
                              <span
                                class="text-underline cursor-pointer"
                                @click="filterDepartment(item[column.name])"
                              >
                                {{ item[column.name] }}</span
                              >
                            </template>
                            <template v-else>
                              {{ item[column.name] }}
                            </template>
                          </slot>
                        </td>

                        <!-- ... -->
                      </tr>
                    </template>
                  </tbody>
                </table>
              </BaseScrollbar>
            </div>
          </template>

          <template v-if="documentTypeTableData.length">
            <div id="document-type" class="col-12">
              <div class="row q-pb-sm">
                <div class="text-bold q-pa-sm">Document Approval Data</div>

                <q-space />

                <ExpandableSearch class="q-mr-sm" @search="searchRow" />

                <FilterItems
                  v-if="false"
                  label="Filter Reports"
                  item="REPORTSDATA"
                  :columns="columns"
                  :filter-by="filterBy"
                  module="repository"
                  :module-id="repositoryId"
                  style="float: right"
                />

                <BaseActionButton
                  v-tooltip.top="'Refresh'"
                  icon="eva-refresh"
                  color="gray"
                  no-border
                  is-flat
                  size="20px"
                  @click="getDocumentBasedTicketReport"
                />
              </div>

              <BaseScrollbar height="330px">
                <table id="documentTable">
                  <thead>
                    <tr>
                      <!-- columns -->

                      <th
                        v-for="column in documentTypeColumns"
                        :key="column.id"
                      >
                        {{ column.label }}
                      </th>

                      <!-- ... -->
                    </tr>
                  </thead>

                  <tbody>
                    <template v-for="item in documentTypeTable">
                      <tr :key="item.id">
                        <!-- columns -->

                        <td
                          v-for="column in documentTypeColumns"
                          :key="column.id"
                        >
                          <slot
                            :name="column.name"
                            :value="item[column.name]"
                            :item="item"
                          >
                            <template v-if="column.type === 'TIME'">
                              {{ lastActionDuration(item[column.name]) }}
                            </template>
                            <template v-else-if="column.type === 'ICON'">
                              <template v-if="item[column.name] === 'Approve'">
                                <BaseIcon name="mdi-check-bold" color="green" />
                              </template>
                              <template
                                v-else-if="item[column.name] === 'Reject'"
                              >
                                <BaseIcon name="mdi-close-circle" color="red" />
                              </template>
                              <template v-else> - </template>
                            </template>
                            <template v-if="column.name === 'ifileName'">
                              <div class="row">
                                <div class="col-auto">
                                  <FileIcon
                                    :mime-type="fileType(item.ifileName)"
                                    class="q-mr-sm"
                                    style="width: 18px"
                                  />
                                </div>
                                <div
                                  class="col text-underline cursor-pointer ellipsis"
                                  @click="openFile(item)"
                                >
                                  {{ item[column.name] }}
                                </div>
                              </div>
                            </template>
                            <template v-else>
                              {{ item[column.name] }}
                            </template>
                          </slot>
                        </td>

                        <!-- ... -->
                      </tr>
                    </template>
                  </tbody>
                </table>
              </BaseScrollbar>
            </div>
          </template>

          <div
            v-for="(item, key) in pieChartData"
            :key="key"
            class="col-4 q-pr-sm q-pt-sm q-pb-sm"
          >
            <PieChart
              :title="item.name"
              :x-data="item.xAxis"
              :y-data="item.yAxis"
              :options="options"
            />
          </div>
        </div>
      </BaseScrollbar>
    </template>

    <Sheet
      v-model="fileSheet"
      no-padding
      width="100vw"
      @input="fileSheet = false"
    >
      <!-- title -->

      <template #title>
        <div class="attachmentFileHeader">
          <div class="avatar q-ml-sm">
            <FileIcon :mime-type="fileType(selectedFile.ifileName)" />
          </div>

          <div class="q-ml-md">
            <div class="filename">
              {{ selectedFile.ifileName }}
            </div>

            <div class="meta">
              <div>
                Archived At:
                {{ $day.format(selectedFile.documentLastModifiedAt) }}
              </div>
              <BaseIcon name="mdi-circle-small" />
              <div>Archived By: {{ selectedFile.documentLastModifiedBy }}</div>
            </div>
          </div>
        </div>
      </template>

      <template #actions>
        <BaseActionButton
          v-tooltip.top="'Actions'"
          is-flat
          icon="eva-menu"
          class="q-mr-sm"
        >
          <q-menu
            transition-show="scale"
            transition-hide="scale"
            anchor="bottom end"
            self="top right"
          >
            <BaseScrollbar width="240px" class="q-pb-sm">
              <!-- columns -->

              <ListItem
                v-for="action in documentActionList"
                :key="action.id"
                :icon="action.icon"
                :label="action.label"
                @click="documentAction(action.id)"
              >
              </ListItem>
            </BaseScrollbar>

            <!-- ... -->
          </q-menu>
        </BaseActionButton>
      </template>

      <div class="attachmentFileFrame">
        <div class="file-list">
          <iframe
            id="file-frame"
            ref="myAttachmentFiles"
            :src="fileLink()"
            frameborder="0"
          ></iframe>
        </div>
      </div>
    </Sheet>
  </div>
</template>

<script>
import PieChart from "@/components/common/charts/PieChart.vue";
import VueHighcharts from "vue2-highcharts";
import Exporting from "highcharts/modules/exporting.js";
import Drilldown from "highcharts/modules/drilldown.js";
import Highcharts from "highcharts";
import { report, workflow, overview } from "@/api/factory.js";
import FilterItems from "@/components/common/display/item-actions/components/FilterItems.vue";
import ExpandableSearch from "@/components/common/ExpandableSearch.vue";
import FileIcon from "@/components/common/FileIcon.vue";
import { pdfSupport, htmlSupport } from "@/helpers/file-format.js";
import Sheet from "@/components/common/popup/Sheet.vue";
import ListItem from "@/components/common/ListItem.vue";

Drilldown(Highcharts);
Exporting(Highcharts);
export default {
  name: "BpmsReportChart",

  components: {
    VueHighcharts,
    PieChart,
    FilterItems,
    ExpandableSearch,
    FileIcon,
    Sheet,
    ListItem,
  },

  props: {
    workflowId: {
      type: Number,
      default: 0,
    },

    repositoryId: {
      type: Number,
      default: 0,
    },

    repositoryFields: {
      type: Array,
      default: () => [],
    },

    workflow: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      workflowCount: [],
      barChartData: [],
      Highcharts: Highcharts,
      chartData: {
        chart: {
          type: "spline",
          height: "330px",
        },
        title: {
          text: "",
        },
        subtitle: {
          text: "",
        },
        xAxis: {
          // categories: [],
          categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
        },
        yAxis: {
          title: {
            text: "Count",
          },
          labels: {
            formatter: function () {
              return this.value;
            },
          },
        },
        tooltip: {
          crosshairs: true,
          shared: true,
        },
        credits: {
          enabled: false,
        },
        legend: {
          enabled: false,
        },
        colors: ["#bfa0fe"],
        plotOptions: {
          series: {
            cursor: "pointer",
            point: {
              events: {
                click: (e) => {
                  e.stopPropagation();
                  if (e.point.name && e.point.y) {
                    if (this.chartDataDrilldownStage.month === "") {
                      this.chartDataDrilldownStage.month = e.point.name;
                      this.getWorkflowChartCount(this.workflowId, e.point.name);
                    } else if (this.chartDataDrilldownStage.week === "") {
                      this.chartDataDrilldownStage.week = e.point.name;
                      this.getWorkflowChartCount(
                        this.workflowId,
                        this.chartDataDrilldownStage.month,
                        e.point.name.replace("Week ", "")
                      );
                    }
                  }
                },
              },
            },
          },
          spline: {
            dataLabels: {
              enabled: true,
            },
          },
        },
        series: [
          // {
          //   name: "Running",
          //   data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 3, 0],
          // },
          // {
          //   name: "Completed",
          //   data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 21, 11],
          // },
        ],
        drilldown: {},
      },
      chartDataDrilldownStage: { month: "", week: "" },
      pieChartData: [],
      departmentColumns: [
        {
          id: this.$nano.id(),
          name: "Department",
          label: "Department",
        },

        {
          id: this.$nano.id(),
          name: "running",
          label: "Running",
        },

        {
          id: this.$nano.id(),
          name: "completed",
          label: "Completed",
        },
      ],
      departmentTable: [],
      documentTypeColumns: [],
      documentTypeTable: [],
      documentTypeTableData: [],
      filterBy: [],
      options: {
        colors: ["#a48965", "#ae845c", "#ae9751", "#7c7f83", "#a6a9aa"],
      },
      fileSheet: false,
      selectedFile: {},
      documentActionList: [
        {
          id: "Download",
          key: "download",
          label: "Download",
          icon: "mdi-download-outline",
        },
        {
          id: "Print",
          key: "print",
          label: "Print",
          icon: "eva-printer-outline",
        },
      ],
    };
  },

  computed: {
    columns() {
      let column = [];
      this.repositoryFields.forEach((field) => {
        if (field.name === "Department" || field.name === "Document Type") {
          column.push({
            id: this.$nano.id(),
            name: field.name,
            label: field.name,
            dataType: field.dataType,
          });
        }
      });

      return column;
    },
  },

  watch: {
    workflowId: {
      deep: true,
      immediate: true,
      handler() {
        let origin = location.origin;
        if (
          origin === "https://demo.ezofis.com" ||
          origin === "http://52.172.32.88"
        ) {
          if (this.workflowId === 2077 || this.workflowId === 2092) {
            this.getWorkflowCount();
            this.getWorkflowChartCount(this.workflowId);
            this.getrepositoryFieldwiseTicketCount();
            this.getDocumentWiseTicketCount();
          }
        } else if (
          origin === "https://edmsuat.sobhaapps.com" ||
          origin === "http://localhost:8080"
        ) {
          if (this.workflowId === 30 || this.workflowId === 55) {
            this.getWorkflowCount();
            this.getWorkflowChartCount(this.workflowId);
            this.getrepositoryFieldwiseTicketCount();
            this.getDocumentWiseTicketCount();
          }
        }
      },
    },

    repositoryFields: {
      deep: true,
      immediate: true,
      handler() {
        let origin = location.origin;
        if (
          origin === "https://demo.ezofis.com" ||
          origin === "http://52.172.32.88" ||
          origin === "http://localhost:8080"
        ) {
          if (
            (this.workflowId === 2077 || this.workflowId === 2092) &&
            this.repositoryFields.length
          ) {
            this.getDocumentBasedTicketReport();
          }
        } else if (
          origin === "https://edmsuat.sobhaapps.com" ||
          origin === "http://localhost:8080"
        ) {
          if (
            (this.workflowId === 30 || this.workflowId === 55) &&
            this.repositoryFields.length
          ) {
            this.getDocumentBasedTicketReport();
          }
        }
      },
    },

    repositoryId: {
      deep: true,
      immediate: true,
      handler() {
        if (this.repositoryId) {
          this.$route.query.repositoryId = this.repositoryId;
        }
      },
    },

    workflow: {
      deep: true,
      immediate: true,
      handler() {
        let origin = location.origin;
        if (
          origin === "https://demo.ezofis.com" ||
          origin === "http://52.172.32.88" ||
          origin === "http://localhost:8080"
        ) {
          if (
            workflow &&
            (this.workflowId === 2077 || this.workflowId === 2092)
          ) {
            this.documentTypeColumns = [];
            let workflowJson = JSON.parse(this.workflow.flowJson);
            this.documentTypeColumns.push(
              {
                id: this.$nano.id(),
                name: "ifileName",
                label: "Document Name",
                type: "SHORT_TEXT",
              },

              {
                id: this.$nano.id(),
                name: "Document Type",
                label: "Document Type",
                type: "SHORT_TEXT",
              },

              {
                id: this.$nano.id(),
                name: "requestNo",
                label: "Document Number",
                type: "SHORT_TEXT",
              },

              {
                id: this.$nano.id(),
                name: "Department",
                label: "Department",
                type: "SHORT_TEXT",
              },

              {
                id: this.$nano.id(),
                name: "documentLastModifiedAt",
                label: "Last Modified At",
                type: "SHORT_TEXT",
              },

              {
                id: this.$nano.id(),
                name: "documentLastModifiedBy",
                label: "Last Modified By",
                type: "SHORT_TEXT",
              }
            );
            workflowJson.blocks.forEach((block) => {
              if (block.type === "INTERNAL_ACTOR") {
                let stageExists = this.documentTypeColumns.some(
                  (column) => block.settings.label === column.name
                );

                if (!stageExists) {
                  this.documentTypeColumns.push({
                    id: this.$nano.id(),
                    name: block.settings.label,
                    label: block.settings.label,
                    type: "ICON",
                  });
                }
              }
            });
          }
        } else if (
          origin === "https://edmsuat.sobhaapps.com" ||
          origin === "http://localhost:8080"
        ) {
          if (workflow && (this.workflowId === 30 || this.workflowId === 55)) {
            this.documentTypeColumns = [];
            let workflowJson = JSON.parse(this.workflow.flowJson);
            this.documentTypeColumns.push(
              {
                id: this.$nano.id(),
                name: "ifileName",
                label: "Document Name",
                type: "SHORT_TEXT",
              },

              {
                id: this.$nano.id(),
                name: "Document Type",
                label: "Document Type",
                type: "SHORT_TEXT",
              },

              {
                id: this.$nano.id(),
                name: "requestNo",
                label: "Document Number",
                type: "SHORT_TEXT",
              },

              {
                id: this.$nano.id(),
                name: "Department",
                label: "Department",
                type: "SHORT_TEXT",
              },

              {
                id: this.$nano.id(),
                name: "documentLastModifiedAt",
                label: "Last Modified At",
                type: "SHORT_TEXT",
              },

              {
                id: this.$nano.id(),
                name: "documentLastModifiedBy",
                label: "Last Modified By",
                type: "SHORT_TEXT",
              }
            );
            workflowJson.blocks.forEach((block) => {
              if (block.type === "INTERNAL_ACTOR") {
                let stageExists = this.documentTypeColumns.some(
                  (column) => block.settings.label === column.name
                );

                if (!stageExists) {
                  this.documentTypeColumns.push({
                    id: this.$nano.id(),
                    name: block.settings.label,
                    label: block.settings.label,
                    type: "ICON",
                  });
                }
              }
            });
          }
        }
      },
    },
  },

  methods: {
    fileType(fileName) {
      if (fileName) return fileName.split(".").reverse()[0].slice(0, 4);
      else "";
    },

    searchRow(search) {
      if (search) {
        this.documentTypeTable = this.documentTypeTableData.filter((row) => {
          for (let cell in row) {
            let index = this.documentTypeColumns.findIndex((column) => {
              return column.name === cell;
            });
            if (index >= 0 && row[cell]) {
              if (
                String(row[cell]).toLowerCase().includes(search.toLowerCase())
              ) {
                return row;
              }
            }
          }
        });
      } else {
        this.documentTypeTable = this.documentTypeTableData;
      }
    },

    lastActionDuration(createdAt) {
      const date = new Date(createdAt);
      const formatter = new Intl.RelativeTimeFormat("en");
      const ranges = {
        years: 3600 * 24 * 365,
        months: 3600 * 24 * 30,
        weeks: 3600 * 24 * 7,
        days: 3600 * 24,
        hours: 3600,
        minutes: 60,
        seconds: 1,
      };
      const secondsElapsed = (date.getTime() - Date.now()) / 1000;
      for (let key in ranges) {
        if (ranges[key] < Math.abs(secondsElapsed)) {
          const delta = secondsElapsed / ranges[key];
          return formatter.format(Math.round(delta), key);
        }
      }
    },

    filterDepartment(department) {
      this.getDocumentWiseTicketCount(department);
      this.getDocumentBasedTicketReport(department);
    },

    fileLink() {
      let origin = location.origin.replace(
        "http://localhost:8080",
        "http://52.172.32.88"
      );
      if (this.selectedFile.ifileName) {
        let ext = this.selectedFile.ifileName.split(".").pop();
        let domainURL = "";
        if (pdfSupport(ext)) {
          if (
            origin === "https://ag-appsvc01.azurewebsites.net" ||
            origin === "https://ag-appsvc05.azurewebsites.net"
          ) {
            domainURL = "https://ag-appsvc04.azurewebsites.net/web/viewer.html";
          } else if (origin === "https://trial.ezofis.com") {
            domainURL = "https://trial.ezofis.com/docsviewer/index.html";
          } else if (origin === "https://app.ezofis.com") {
            domainURL =
              "https://ezdocsviewer.azurewebsites.net/web/viewer.html";
          } else {
            domainURL = `${origin}/PDFViewer/web/viewer.html`;
          }
        } else if (!htmlSupport(ext) && origin === "http://52.172.32.88") {
          domainURL = "http://52.172.32.88/DocsViewer/index.html";
        } else {
          return `${process.env.VUE_APP_API_URL}/file/view/${this.$store.state.session.tenantId}/${this.$store.state.session.id}/${this.repositoryId}/${this.selectedFile.id}/2`;
        }
        return `${domainURL}?tId=${this.$store.state.session.tenantId}&uId=${this.$store.state.session.id}&rId=${this.repositoryId}&itemId=${this.selectedFile.itemId}&type=2&filename=${this.selectedFile.name}`;
      }
    },

    openFile(fileObj) {
      this.selectedFile = fileObj;
      this.fileSheet = true;
    },

    documentAction(action, viewPrint) {
      if (action === "Email_Sharing") {
        //
      } else if (action === "Print") {
        if (this.selectedFile.name) {
          if (viewPrint) {
            let link = "";
            if (this.selectedFile.initiate) {
              let type = 1;
              if (
                this.repositoryDetails.fieldsType === "STATIC" &&
                this.workflow.initiatedBy === "FORM"
              ) {
                type = 2;
              }
              link = `${process.env.VUE_APP_API_URL}/uploadandindex/view/${this.$store.state.session.tenantId}/${this.selectedFile.id}/${type}/1`;
            } else {
              link = `${process.env.VUE_APP_API_URL}/file/view/${this.$store.state.session.tenantId}/${this.$store.state.session.id}/${this.repositoryId}/${this.selectedFile.itemId}/2`;
            }
            window.open(link, "_blank");
          } else {
            let ext = this.selectedFile.name.split(".").pop();
            if (pdfSupport(ext)) {
              localStorage.setItem("filePrint", true);
              localStorage.setItem("filePrintId", this.selectedFile.itemId);
            } else {
              this.$refs.myAttachmentFiles.contentWindow.print();
            }
          }
        }
        this.filePrintSession();
      } else if (action === "Download") {
        let link = "";

        if (this.selectedFile.initiate) {
          let type = 1;
          if (
            this.repositoryDetails.fieldsType === "STATIC" &&
            this.workflow.initiatedBy === "FORM"
          ) {
            type = 2;
          }
          link = `${process.env.VUE_APP_API_URL}/uploadandindex/view/${this.$store.state.session.tenantId}/${this.selectedFile.itemId}/${type}/2`;
        } else {
          link = `${process.env.VUE_APP_API_URL}/menu/file/download/${this.$store.state.session.tenantId}/${this.$store.state.session.id}/${this.repositoryId}/${this.selectedFile.itemId}/2`;
        }
        window.open(link, "_blank");
        //this.$alert.success(`file downloaded successfully`);
      }
    },

    async getWorkflowCount() {
      this.$store.commit("showLoadingBar");
      this.workflowCount = [];
      const { error, payload } = await overview.getRequestCountByUserId(
        this.workflowId
      );
      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }

      if (this.slaSettings === 2 || this.slaSettings === 4) {
        this.workflowCount.push(
          {
            workflowStatus: "Total Requests",
            matchingKey: "totalRequests",
          },
          {
            workflowStatus: "Completed Requests",
            matchingKey: "completedCount",
          },
          {
            workflowStatus: "Inbox Requests",
            matchingKey: "inboxCount",
          },
          {
            workflowStatus: "Sent Requests",
            matchingKey: "processCount",
          },
          {
            workflowStatus: "SLA Overdue",
            matchingKey: "slaOverdue",
          },
          {
            workflowStatus: "SLA Processing",
            matchingKey: "slaProcessing",
          },
          {
            workflowStatus: "SLA Achieved",
            matchingKey: "slaAchieved",
          }
        );
      } else {
        this.workflowCount.push(
          {
            workflowStatus: "Total Requests",
            matchingKey: "totalRequests",
          },
          {
            workflowStatus: "Completed Requests",
            matchingKey: "completedCount",
          },
          {
            workflowStatus: "Inbox Requests",
            matchingKey: "inboxCount",
          },
          {
            workflowStatus: "Sent Requests",
            matchingKey: "processCount",
          }
        );
      }

      this.workflowCount.forEach((entry) => {
        const matchingKey = Object.keys(payload).find(
          (key) => key === entry.matchingKey
        );

        if (matchingKey) {
          entry.totalCount = payload[matchingKey];
        }
      });
    },

    async getWorkflowChartCount(id, month = "", weekno = "") {
      this.$store.commit("showLoadingBar");
      this.chartData.series = [];
      const weekNumber = weekno.replace(/\D/g, "");
      let input = {
        year: new Date().getFullYear(),
        month: month,
        weekno: weekNumber,
      };
      const { error, payload } = await report.getWorkflowChartByUserId(
        id,
        input
      );

      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }

      if (payload) {
        if (payload.completed && payload.initiated) {
          let yAxis = [];
          // this.chartData.xAxis.categories = Object.keys(payload.completed);

          yAxis.push({
            name: "Running",
            data: Object.values(payload.initiated).map(Number),
          });

          yAxis.push({
            name: "Completed",
            data: Object.values(payload.completed).map(Number),
          });

          this.chartData.series = yAxis;

          let lineCharts = this.$refs.refWorkflowChart;
          lineCharts.removeSeries();

          lineCharts.addSeries(this.chartData.series[0]);
          lineCharts.addSeries(this.chartData.series[1]);
        }
      }
    },

    async getrepositoryFieldwiseTicketCount() {
      this.$store.commit("showLoadingBar");
      let input = {
        groupName: "Department",
        fieldName: "Document Type",
      };
      const { error, payload } =
        await overview.getRepositoryFieldwiseTicketCount(
          this.workflowId,
          input
        );
      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }

      if (payload) {
        this.departmentColumns = [];

        payload.forEach((value) => {
          Object.keys(value).forEach((key) => {
            const columnExists = this.departmentColumns.some(
              (column) => column.name === key
            );

            if (!columnExists) {
              this.departmentColumns.push({
                id: this.$nano.id(),
                name: key,
                label: key.toUpperCase(),
              });
            }
          });
        });
      }

      this.departmentTable = payload;
    },

    async getDocumentBasedTicketReport(department = "") {
      this.$store.commit("showLoadingBar");
      let input = {};
      if (department) {
        input = {
          repositoryFields: [],
          stageName: [],
          filterBy: [
            {
              filters: [
                {
                  criteria: "Department",
                  condition: "IS_EQUALS_TO",
                  value: department,
                  dataType: "SHORT_TEXT",
                },
              ],
              groupCondition: "",
            },
          ],
        };
      } else {
        input = {
          repositoryFields: [],
          stageName: [],
          filterBy: [],
        };
      }

      this.repositoryFields.forEach((field) => {
        if (field.name === "Department" || field.name === "Document Type") {
          input.repositoryFields.push(field.name);
        }
      });
      let workflowJson = JSON.parse(this.workflow.flowJson);

      workflowJson.blocks.forEach((block) => {
        if (block.type === "INTERNAL_ACTOR") {
          let stageExists = input.stageName.some(
            (stage) => block.settings.label === stage
          );

          if (!stageExists) {
            input.stageName.push(block.settings.label);
          }
        }
      });

      const { error, payload } = await overview.getDocumentBasedTicketReport(
        this.workflowId,
        input
      );
      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }

      if (payload) {
        if (payload.data[0].value.length) {
          let dueColumn = this.documentTypeColumns.find(
            (row) => row.label === "Due Since"
          );
          if (!dueColumn) {
            this.documentTypeColumns.push({
              id: this.$nano.id(),
              name: "ticketCreatedAt",
              label: "Due Since",
              type: "TIME",
            });
          }

          this.documentTypeTable = payload.data[0].value;
          this.documentTypeTableData = payload.data[0].value;
        }
      }
    },

    async getDocumentWiseTicketCount(department = "") {
      this.pieChartData = [];
      this.$store.commit("showLoadingBar");
      let input = {
        department: department,
      };

      const { error, payload } = await overview.getDocumentWiseTicketCount(
        this.workflowId,
        input
      );
      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }

      if (payload) {
        let overAll = payload.overAll;
        let document = payload.document;

        overAll.forEach((value) => {
          delete value["Document Type"];
          this.pieChartData.push({
            key: this.$nano.id(),
            name: "Overall",
            xAxis: Object.keys(value),
            yAxis: Object.values(value),
          });
        });

        document.forEach((value) => {
          let documentType = value["Document Type"];
          delete value["Document Type"];
          let xAxis = Object.keys(value);
          let yAxis = Object.values(value);

          this.pieChartData.push({
            key: this.$nano.id(),
            name: documentType,
            xAxis: xAxis,
            yAxis: yAxis,
          });
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.reports {
  .text-underline {
    text-decoration: underline;
  }
  .link-style {
    cursor: pointer;
    text-decoration: none;
  }

  .link-style:hover {
    text-decoration: underline;
  }

  .wrapper {
    position: relative;
    height: calc(100vh - 208px);
  }

  .chart {
    height: 375px;
    padding: 10px;
    border-radius: 10px;
    background-color: var(--component-bg-color);
    border: 1px solid var(--component-bg-color);

    &.chartBox {
      height: 100px;
    }
  }

  .header {
    text-anchor: start;
    font-size: 15px;
    font-weight: 700;
    fill: rgb(55, 61, 63);
  }

  .header-top {
    border-bottom: 1px solid var(--divider-color);
    padding-bottom: 8px;
    margin-bottom: 8px;
  }

  .label {
    color: #a391cf !important;
  }

  .count {
    color: #8162cc;
    font-weight: 800;
    font-size: 24px;
  }

  .group-by {
    //background-color: var(--deep-orange);
    padding: 4px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    border: 1px solid #e9a177;
    //color: white;

    .groupLabel {
      font-weight: 500;
    }
  }

  .list {
    background-color: #e1f5f9;
    border-radius: 6px;
    height: 100%;
    border: 1px solid #e1f5f9;
    cursor: pointer;
    font-size: 12px;

    &.active-list {
      background-color: $secondary-2 !important;
    }

    &:hover {
      background-color: $secondary-2 !important;
    }
  }

  #workflow {
    padding: 10px;
    border-radius: 10px;
    background-color: var(--component-bg-color);
    border: 1px solid var(--component-bg-color);

    #workflowTable {
      border: 1px solid var(--divider-color);
      border-radius: 10px;
      white-space: nowrap;
      width: 100%;

      tr {
        height: 36px;

        th {
          color: var(--secondary);
          text-align: left;
        }
      }

      th,
      td {
        padding: 8px;
        font-weight: 500;
      }

      tr:first-child td {
        border-top: 1px solid var(--divider-color); /* Add top border to the first row */
      }
    }
  }

  #department-type {
    padding: 10px;
    border-radius: 10px;
    background-color: var(--component-bg-color);
    border: 1px solid var(--component-bg-color);

    #departmentTable {
      border: 1px solid var(--divider-color);
      border-radius: 10px;
      white-space: nowrap;
      width: 100%;

      tr {
        height: 36px;

        th {
          color: var(--secondary);
          text-align: left;
        }
      }

      th,
      td {
        padding: 8px;
        font-weight: 500;
      }

      tr:first-child td {
        border-top: 1px solid var(--divider-color); /* Add top border to the first row */
      }
    }
  }

  #document-type {
    padding: 10px;
    border-radius: 10px;
    background-color: var(--component-bg-color);
    border: 1px solid var(--component-bg-color);

    #documentTable {
      border: 1px solid var(--divider-color);
      border-radius: 10px;
      white-space: nowrap;
      width: 100%;

      tr {
        height: 36px;

        th {
          color: var(--secondary);
          text-align: left;
        }
      }

      th,
      td {
        padding: 8px;
        font-weight: 500;
      }

      tr:first-child td {
        border-top: 1px solid var(--divider-color); /* Add top border to the first row */
      }
    }
  }
}

#sheet .attachmentFileHeader {
  display: flex;
  align-items: center;
  text-transform: none !important;
  .avatar {
    width: 36px;
    height: 36px;
  }

  .filename {
    font-size: 16px !important;
  }

  .meta {
    @extend .text-sm;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    color: var(--icon-color-inverted);
    font-weight: normal !important;
  }
}

#sheet .attachmentFileFrame {
  min-height: calc(100vh - 61px);
  background-color: var(--body-bg-color);
  display: flex;

  .file-list {
    flex: 1;
  }

  .aside {
    width: 380px;
    background-color: var(--component-bg-color);
  }

  #file-frame {
    width: 100%;
    height: 100%;
  }
}

#sheet .fileFrame {
  min-height: calc(100vh - 120px);
  background-color: var(--body-bg-color);
  display: flex;

  .file-list {
    flex: 1;
  }

  .aside {
    width: 380px;
    background-color: var(--component-bg-color);
  }

  #file-frame {
    width: 100%;
    height: 100%;
  }
}
</style>
